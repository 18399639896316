<template>
	<div>
		<div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/auth">Auth</router-link> |
			<router-link to="/authorized">Authorized</router-link>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	name: "App",
};
</script>
