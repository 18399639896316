<template>
	<div>Authorized</div>
</template>

<script>
const qs = require("querystring");

export default {
	name: "Authorized",
	async mounted() {
		const { code /*, expires_in */ } = this.$route.query;
		let res;
		let body;

		try {
			res = await fetch(
				`${process.env.VUE_APP_BACKEND_HOSTNAME}/authorized?${qs.stringify({
					code,
				})}`,
				{
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify({
						code,
					}),
				}
			);

			body = await res.json();

			res = await fetch(
				`${process.env.VUE_APP_BACKEND_HOSTNAME}/sheets?${qs.stringify({
					accessToken: body.access_token,
				})}`,
				{
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				}
			);

			body = await res.json();

			console.log(body.sheets);
		} catch (error) {
			console.log(error);
		}
	},
};
</script>
