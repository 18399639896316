<template>
	<div>Auth</div>
</template>

<script>
export default {
	name: "Auth",
	async mounted() {
		try {
			const res = await fetch(`${process.env.VUE_APP_BACKEND_HOSTNAME}/auth`, {
				method: "GET",
				headers: {
					"content-type": "application/json",
				},
			});

			const body = await res.json();

			window.location = body.authorizationUri;
		} catch (error) {
			console.log(error);
		}
	},
};
</script>
