import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Auth from "@/views/Auth.vue";
import Authorized from "@/views/Authorized.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/auth",
		name: "Auth",
		component: Auth,
	},
	{
		path: "/authorized",
		name: "Authorized",
		component: Authorized,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
